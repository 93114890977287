import styles from "./../styles/demo.module.scss"
import formStyles from "./../styles/commons/form.module.scss"
import React, { useContext, useState } from "react"
import Layout from "../components/layout/layout"
import { graphql, navigate, useStaticQuery } from "gatsby"
import { Field, Form, Formik } from "formik"
import * as Yup from "yup"
import FormError from "../components/forms/form-error/form-error"
import CountrySelect from "../components/forms/country-select/country-select"
import { APIHelper } from "../utils/api-helper"
import { REGEX_EMAIL, REGEX_PHONE_NUMBER } from "../utils/regex"
import {
  FULL_NAME_MAX_LEN,
  FULL_NAME_MIN_LEN,
  PRIVACY_POLICY_PAGE_URL,
} from "../utils/constants"
import SEO from "../components/seo"
import withAppContext from "../contexts/app.context"
import { ShowAnnouncementContext } from "../contexts/show-announcement.context"
import * as Recaptcha from "react-recaptcha"
import { ENV_RECAPTCHA_PUBLIC_KEY } from "../utils/secrets"
import { Helper } from "../utils/helper"

const consultSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(FULL_NAME_MIN_LEN, "Full name is too Short!")
    .max(FULL_NAME_MAX_LEN, "Full name is too Long!")
    .required("This field is required"),
  title: Yup.string()
    .trim()
    .min(2, "Title is too Short!")
    .max(50, "Title is too Long!")
    .required("This field is required"),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .matches(REGEX_EMAIL, "Please enter a valid email")
    .required("This field is required"),
  phone: Yup.string()
    .trim()
    .matches(REGEX_PHONE_NUMBER, "Please enter a valid phone number"),
  companyName: Yup.string()
    .trim()
    .required("This field is required"),
  country: Yup.string().required("This field is required"),
  companyUseCase: Yup.string().trim(),
  policyAgree: Yup.boolean().oneOf(
    [true],
    `You must agree to Volterra's privacy policy`
  ),
})

const ConsultPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: { relativePath: { eq: "consult" }, category: { eq: "seo" } }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [requestSending, setRequestSending] = useState(false)
  const [requestSent, setRequestSent] = useState(false)

  const { showAnnouncement } = useContext(ShowAnnouncementContext)

  let recaptchaInstance
  let formValue

  const onloadCallback = () => {}

  const onFormSubmit = values => {
    formValue = values
    if (ENV_RECAPTCHA_PUBLIC_KEY) {
      recaptchaInstance.execute()
      return
    }
    postForm(values)
  }

  const verifyCallback = token => {
    const request = {
      ...formValue,
      token,
    }
    postForm(request)
  }

  const postForm = values => {
    const { policyAgree, ...data } = consultSchema.cast(values)
    setRequestSending(true)
    APIHelper.sendConsultation(data)
      .then(() => {
        setRequestSending(false)
        setRequestSent(true)
        navigate("/thanks-consult")
      })
      .catch(() => {
        setRequestSending(false)
      })
  }

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/consult"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />
      <div className={styles.consultContainer}>
        <div
          className={
            `container ${styles.container}` +
            (showAnnouncement ? styles.containerIndent : "")
          }>
          <div className={`row ${styles.form__wrapper}`}>
            <Formik
              initialValues={{
                fullName: "",
                title: "",
                email: "",
                phone: "",
                companyName: "",
                country: "",
                companyUseCase: "",
                policyAgree: false,
              }}
              validationSchema={consultSchema}
              onSubmit={onFormSubmit}>
              {({ errors, touched, values }) => {
                return (
                  <Form
                    id="website-request-consultation"
                    className={`${styles.form} col-12 col-xl-6`}
                    ves-e2e-test="demo/consult-form">
                    <h2 className={`${styles.title}`}>
                      Volterra consulting session
                    </h2>
                    <p
                      className={`${styles.description} ${styles.descriptionConsult}`}>
                      Please answer a couple of questions to help us tailor a
                      session for your company
                    </p>
                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="fullName">
                          Full name *
                        </label>
                        <Field
                          id="fullName"
                          type="input"
                          name="fullName"
                          className={`form-control ${formStyles.formControl}${
                            errors.fullName && touched.fullName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your full name"
                        />
                        <FormError name="fullName" />
                      </div>
                      <div className="col-12 col-sm-6 mb-4">
                        <label className={formStyles.formLabel} htmlFor="title">
                          Title *
                        </label>
                        <Field
                          id="title"
                          type="input"
                          name="title"
                          className={`form-control ${formStyles.formControl}${
                            errors.title && touched.title ? " is-invalid" : ""
                          }`}
                          placeholder="Enter your job role"
                        />
                        <FormError name="title" />
                      </div>
                    </div>
                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 col-sm-6 mb-4">
                        <label className={formStyles.formLabel} htmlFor="email">
                          Email *
                        </label>
                        <Field
                          id="email"
                          type="email"
                          name="email"
                          className={`form-control ${formStyles.formControl}${
                            errors.email && touched.email ? " is-invalid" : ""
                          }`}
                          placeholder="Enter your business email"
                        />
                        <FormError name="email" />
                      </div>
                      <div className="col-12 col-sm-6 mb-4">
                        <label className={formStyles.formLabel} htmlFor="phone">
                          Phone
                        </label>
                        <Field
                          id="phone"
                          type="input"
                          name="phone"
                          className={`form-control ${formStyles.formControl}${
                            errors.phone && touched.phone ? " is-invalid" : ""
                          }`}
                          placeholder="Enter your phone number"
                        />
                        <FormError name="phone" />
                      </div>
                    </div>
                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="companyName">
                          Company *
                        </label>
                        <Field
                          id="companyName"
                          type="text"
                          name="companyName"
                          className={`form-control ${formStyles.formControl}${
                            errors.companyName && touched.companyName
                              ? " is-invalid"
                              : ""
                          }`}
                          placeholder="Enter your company's name"
                        />
                        <FormError name="companyName" />
                      </div>
                      <div className="col-12 col-sm-6 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="country">
                          Country *
                        </label>
                        <CountrySelect
                          id="country"
                          name="country"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.country && touched.country
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <FormError name="country" />
                      </div>
                    </div>
                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="col-12 mb-4">
                        <label
                          className={formStyles.formLabel}
                          htmlFor="companyUseCase">
                          Solutions interested in:
                        </label>
                        <Field
                          id="companyUseCase"
                          as="select"
                          name="companyUseCase"
                          className={
                            "form-control" +
                            ` ${formStyles.formControl}` +
                            (errors.companyUseCase && touched.companyUseCase
                              ? " is-invalid"
                              : "")
                          }>
                          <option value="" disabled>
                            Choose solution
                          </option>
                          <option>Multi-Cloud</option>
                          <option>Edge Cloud</option>
                          <option>Network Cloud</option>
                        </Field>
                        <FormError name="companyUseCase" />
                      </div>
                    </div>
                    <div className={`${formStyles.formGroup} form-row`}>
                      <div className="form-check mb-4">
                        <Field
                          id="policyAgree"
                          type="checkbox"
                          name="policyAgree"
                          className={`form-check-input ${
                            errors.companyName && touched.companyName
                              ? " is-invalid"
                              : ""
                          }`}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="policyAgree">
                          I agree to the{" "}
                          <a
                            ves-e2e-test="demo/consult-form-policy-link"
                            aria-label="Privacy Policy"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={PRIVACY_POLICY_PAGE_URL}>
                            privacy policy
                          </a>
                        </label>
                        <FormError name="policyAgree" />
                      </div>
                    </div>
                    <div className={`${formStyles.formGroup} form-row`}>
                      <button
                        ves-e2e-test="consult/form-submit-btn"
                        aria-label="Send"
                        type="submit"
                        disabled={requestSending || requestSent}
                        className={`col btn btn-primary ${styles.submit__button}`}>
                        {requestSending || requestSent
                          ? "Sending..."
                          : "Request session"}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
            <div className="col-12 col-xl-6 d-flex justify-content-center">
              <div className={styles.example}>
                <div className={styles.example__image} />
                <div className={styles.example__body}>
                  <p className={styles.example__text}>
                    Discover what distributed cloud services can do for you:
                  </p>
                  <ul className={styles.example__list}>
                    <li className={styles.example__listItem}>
                      <div>
                        Connect and secure your apps across multiple cloud
                        clusters or providers
                      </div>
                    </li>
                    <li className={styles.example__listItem}>
                      <div>
                        Deploy workloads on our network for superior performance
                      </div>
                    </li>
                    <li className={styles.example__listItem}>
                      <div>
                        Cloudify your edge sites to drive business
                        transformation
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Helper.isBrowser() && ENV_RECAPTCHA_PUBLIC_KEY && (
        <Recaptcha
          ref={e => (recaptchaInstance = e)}
          sitekey={ENV_RECAPTCHA_PUBLIC_KEY}
          onloadCallback={onloadCallback}
          render="explicit"
          size="invisible"
          verifyCallback={verifyCallback}
        />
      )}
    </Layout>
  )
}

export default withAppContext(ConsultPage)
